/**
 * Created by rhett on 2018/6/15.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./MBHeader.css";
import Swiper from "react-id-swiper";
import Facade from "./Facade";
import Notify from "./Notify";
import App from "./App";
import {Link, Route, Switch} from "react-router-dom";


export default class MBHeader extends Component
{
  static propTypes = {
    //height :PropTypes.number,//高度
  };
  static _instance = null;

  constructor(props)
  {
    super(props);
    this.state = {shrinkPercent: 0};
    MBHeader._instance = this;
  }

  static spacerH = 0;
  static shrink(percent)
  {

  }

  componentWillUnmount()
  {
    window.onscroll = null;

  }

  componentWillMount()
  {

    window.onscroll = this.onScroll.bind(this);
  }

  componentDidMount()
  {
  }

  onScroll()
  {
    var t = document.documentElement.scrollTop || document.body.scrollTop;
    let h = document.getElementById("MBHeader").clientHeight;
    //console.log("MBHeader/onScroll", t, h);
    let shrinkPercent = t / h;
    if ( shrinkPercent >1 )shrinkPercent = 1;
    if ( shrinkPercent < 0) shrinkPercent = 0;
    this.setState({shrinkPercent}); // 199是banner高
  }


  render()
  {
    let {shrinkPercent} = this.state;
    let clazz = shrinkPercent>=1.0? "MBHeader fullBg": "MBHeader";
    return (
      <div className={clazz} id="MBHeader">
        <div className="fixed">
          <MBHeaderInfo shrinkPercent={shrinkPercent}/>
          <Switch>
            <Route path={App.ROUTE_ROOTPATH} exact  component={MBHeaderMenu} />
            <Route path={App.ROUTE_ROOTPATH+":page"}  component={MBHeaderMenu} />
          </Switch>
        </div>
        <MBHeaderSpacer/>
      </div>);
  }

}
class MBHeaderSpacer extends  Component
{
  constructor(props)
  {
    super(props);
    this.state = { h : MBHeader.spacerH };
  }

  componentWillUnmount()
  {
    Facade.removeListener(Notify.MBHEADER_CONTAIN_H_CHANGED, this._onResize);

  }

  componentWillMount()
  {
    this._onResize = this.onResize.bind(this);
    Facade.regListener(Notify.MBHEADER_CONTAIN_H_CHANGED, this._onResize);
  }

  componentDidMount(){
    if(MBHeader.spacerH != this.state.h )
    {
      this.setState({h:MBHeader.spacerH});
    }
  }

  onResize( n )
  {
    this.setState({h : n.data});
  }

  render(){
    let { h } = this.state;
    return (
      <div style={{height: h }}></div>
    )
  }
}

class MBHeaderInfo extends Component
{
  static propTypes ={
    shrinkPercent: PropTypes.number,
  };

  componentWillUnmount()
  {
    Facade.removeListener(Notify.WIN_W_CHANGED, this._onResize);
  }

  componentWillMount()
  {
    this._onResize = this.onResize.bind(this);
    Facade.regListener(Notify.WIN_W_CHANGED, this._onResize);
    this.calcSpacerSize();
  }

  onResize( n ){
    this.setState({winW : n.data});
    this.calcSpacerSize();
  }

  calcSpacerSize()
  {

    // 估算最大h
    let w = App.screenHelper.getWinW();
    let logoW = (w * 0.2);//    width:20%;  height:auto;
    if(logoW < 88) logoW = 88;// min-width:88px;
    if(logoW>200) logoW = 200;//max-width:200px;
    let logoH = logoW;//logo w = h
    MBHeader.spacerH = LOGO_TOP_MAX+logoH+TITLE_MARGINTOP_MAX+TITLE_H_MAX+SUBTITLE_MARGINTOP_MAX+SUBTITLE_H_MAX+SUBTITLE_MARGINBOTTOM_MAX + MENU_H;
    Facade.sendNotify(Notify.MBHEADER_CONTAIN_H_CHANGED,this, MBHeader.spacerH );
  }

  interp( max ,min , percent)
  {
    return max - (max-min)* percent;
  }
  render()
  {
    let {shrinkPercent} = this.props;
    let w = App.screenHelper.getWinW();
    if ( w > 1080 ) w = 1080; // 和css里    .MBHeader .info{ max-width: 1080px;匹配

    let logoW = (w * 0.2);//    width:20%;  height:auto;
    if(logoW < 88) logoW = 88;// min-width:88px;
    if(logoW>200) logoW = 200;//max-width:200px;

    logoW = this.interp( logoW , 45 , shrinkPercent);
    let logoH = logoW;//logo w = h
    let logoTop = this.interp(LOGO_TOP_MAX , 2, shrinkPercent); // top:40
    let logoLeft = (w - logoW) / 2;
    if ( shrinkPercent > 0.9 )
      logoLeft = this.interp(logoLeft ,10,(shrinkPercent-.9)*10);

    let titleTop = logoTop + this.interp(logoH ,0,shrinkPercent) + TITLE_MARGINTOP_MAX;
    if ( logoW <= 45 )
    {
      titleTop = this.interp(titleTop ,6, shrinkPercent);
    }
    let titleH = this.interp(TITLE_H_MAX,18,shrinkPercent);// 40 ~18

    let subTitleMarginTop = this.interp(SUBTITLE_MARGINTOP_MAX,7 ,shrinkPercent);
    let subTitleTop = titleTop + titleH + subTitleMarginTop;// titleHeight = 40;
    let subTitleH = this.interp(SUBTITLE_H_MAX,14,shrinkPercent);// 18~14

    let subTitleMarginBottom = this.interp(SUBTITLE_MARGINBOTTOM_MAX , 5, shrinkPercent);
    let infoH = subTitleTop + subTitleH + subTitleMarginBottom;// subTitleH = 18;


    let logoStl = { width : logoW +"px", height: logoH +"px", top: logoTop+"px",left:logoLeft+"px" };
    let titleStl={ top : titleTop +"px",fontSize:titleH };
    let subTitleStl={ top : subTitleTop+"px",fontSize:subTitleH};
    let infoStl = {height: infoH+"px"};
    return (
      <div className="info " style={infoStl}>
        <img className="logo" src={require("./img/mousebomb-logo.png")} alt="" style={logoStl} />
        <div className="title outlineWhite" style={titleStl}>MOUSEBOMB</div>
        <div className="subTitle outlineWhite" style={subTitleStl}>互联网应用与开发</div>
      </div>
    );
  }
}
const  LOGO_TOP_MAX = 20;
const  TITLE_MARGINTOP_MAX = 6;
const  TITLE_H_MAX = 40;
const  SUBTITLE_MARGINTOP_MAX = 6;
const  SUBTITLE_H_MAX = 18;
const  SUBTITLE_MARGINBOTTOM_MAX = 20;
const  MENU_H = 50;

class MBHeaderMenu extends Component
{
  static propTypes = {
    activeIndex: PropTypes.number,
  };
  render()
  {
    let {page} = this.props.match.params;

    return (
      <div className="menu">
        <Swiper
          ref={(swiper) =>
          {
            this.swiper = swiper;
          }}
          loop={false}
          // spaceBetween={0}
          slidesPerView={"auto"}
          slidesOffsetBefore={0}
          // slidesOffsetAfter={0}
          centeredSlides={false}
          shouldSwiperUpdate
        >
          <Link className={(!page) ?"active":""} to="./">关于</Link>
          <Link className={page=="resume"?"active":""} to="resume">履历</Link>
          <Link className={page=="projects"?"active":""} to="projects">项目</Link>
          <Link className={page=="moments"?"active":""} to="moments">近况</Link>
        </Swiper>

      </div>
    );
  }
}