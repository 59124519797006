/**
 * Created by rhett on 2018/6/17.
 */

const MIN = 60000;
const FIVE_MINS = 300000;
const HOUR = 3600000;
const DAY = 86400000;
const DAY2 = 86400000 * 2;
const MONTH = 2592000000;
const YEAR = 31536000000;
export default class TimeUitl
{
  /** 时间 for  邻里圈列表 */
  static socialTime4TopicList(time)
  {
    const nowStamp = Date.now();
    let now = new Date();
    let delta = (nowStamp - time.valueOf());
    let d = time.getDate();
    if (delta < HOUR)
    {
      //一小时内
      return Math.round(delta / MIN) + "分钟前";
    } else if (delta < DAY)
    {
      // 24小时内
      return Math.floor(delta / HOUR) + "小时前";
    } else if (delta < MONTH)
    {
      // 超过24小时 < 一个月内
      now.setHours(0, 0, 0, 0);
      time.setHours(0, 0, 0, 0);
      delta = now.valueOf() - time.valueOf();
      let days = Math.floor(delta / DAY);
      if (days == 1)
        return "昨天";
      return days + "天前";
    } else if (time.getFullYear() == now.getFullYear())
    {
      // 超过1一个月
      // 本年内
      return (time.getMonth() + 1) + "月" + d + "日";
    } else
    {
      // 跨年
      return (time.getFullYear()) + "年" + (time.getMonth() + 1) + "月" + d + "日";
    }
  }
}