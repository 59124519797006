/**
 * Created by rhett on 2018/6/16.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./MomentsPage.css";
import Facade from "./Facade";
import Notify from "./Notify";
import iconDouban from "./img/douban.png";
import iconWp from "./img/wordpress.png";
import iconPer from "./img/per.png";
import iconTWeibo from "./img/tweibo.png";
import TimeUitl from "./TimeUtil";


export default class MomentsPage extends Component
{
  static propTypes = {
    //height :PropTypes.number,//高度
  };

  constructor(props)
  {
    super(props);
    this.state = { moments : []};
    this.model  = new MomentsModel();
  }

  componentWillUnmount()
  {
    Facade.removeListener(Notify.MOMENTS_LIST_CHANGED,this._onMOMENTS_LIST_CHANGED);
  }

  componentWillMount()
  {
    this._onMOMENTS_LIST_CHANGED = this.onMOMENTS_LIST_CHANGED.bind(this);
    Facade.regListener(Notify.MOMENTS_LIST_CHANGED,this._onMOMENTS_LIST_CHANGED);
    this.model.run();
  }

  componentDidMount()
  {
  }

  onMOMENTS_LIST_CHANGED(n )
  {
    this.setState({ moments : n.data});
  }

  render()
  {
    let {moments } = this.state;
    return (
      <div className="MomentsPage">
        {moments.map( (li,i)=>{
          return (
            <MomentLi  {...li}/>
          )
        })}
      </div>);
  }

}

const isHttps = document.location.href.substr(0,5).toLowerCase()=="https";
const protocal = isHttps?"https://":"http://";

const perRSS = 'http://www.flashj.cn/personalblog/?feed=rss2';
//const perRSS = 'http://127.0.0.1:8083/2013/wpRss.xml';
const wpRSS = 'http://www.flashj.cn/wp/?feed=rss2';
const doubanRSS = 'http://www.douban.com/feed/people/mousebomb/interests';
const tweiboHTML = 'http://show.v.t.qq.com/index.php?c=show&a=index&n=RhettGao&w=0&h=548&fl=1&l=30&o=17&co=0';
//const tweiboHTML = 'http://127.0.0.1:8083/2013/tweibo.html';
const SRCTYPE_WP = 'wp';
const SRCTYPE_PER = 'per';
const SRCTYPE_WEIBO = 'weibo';
const SRCTYPE_TWEIBO = 'tweibo';
const SRCTYPE_DOUBAN = 'douban';

const GETFILE_PROXY = protocal+"www.mousebomb.org/modules/getfile.php?url=";


class MomentLi extends Component
{
  static propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    intro: PropTypes.array, // array [string,...]
    date: PropTypes.any,
    link: PropTypes.string,
    img: PropTypes.string,
  };
  constructor(props){
    super(props);
    this.state = {spawn : false };
  }
  componentWillUnmount()
  {
    clearTimeout(this.itv);
  }
  componentDidMount()
  {
    this.itv = setTimeout( ()=>{
      this.setState({spawn:true});
    } , 1);
  }

  render()
  {
    let {type, title, date, link, img ,intro} = this.props;
    let { spawn } = this.state;
    let iconSrc = null;
    switch (type)
    {
      case SRCTYPE_DOUBAN:
        iconSrc = iconDouban;
        break;
      case SRCTYPE_WP:
        iconSrc = iconWp;
        break;
      case SRCTYPE_PER:
        iconSrc = iconPer;
        break;
      case SRCTYPE_TWEIBO:
        iconSrc = iconTWeibo;
        break;
    }

    let additionalLink  = null;
    if (type == SRCTYPE_WP || type== SRCTYPE_PER) {
      additionalLink = (<span className="highlight">阅读全文</span>);
    }
    return (
      <div className={"MomentLi " + type + (spawn?" spawn ":"")}>
        <div className="hr"></div>
        <div className="row">


          <div className="col icon">
            <img src={iconSrc} alt=""/>
          </div>
          <div className="col content">
            <div className="date">{TimeUitl.socialTime4TopicList(date)}</div>
            <a className="title" href={link}>{title}</a>
            {intro && (
              <a className="intro" href={link}>
                {intro.map((li, i) =>
                {
                  if (li)
                  {
                    return (<p key={"intro-" + i}>{li}{ i ==intro.length-1 && additionalLink}</p>);
                  }
                })}
              </a>
            )}
            { img &&
            <a className="img" href={link}>
              <img
                src={GETFILE_PROXY + img}
                alt=""/>
            </a>
            }
          </div>
        </div>


      </div>
    )
  }
}


class MomentsModel
{

  run(){
    this.  recentList = [];
    // 获得数据
    this.getData(wpRSS , (text)=>{ this.joinRecentList( this.parseBlog(text,SRCTYPE_WP)) });
    this.getData(perRSS , (text)=>{ this.joinRecentList( this.parseBlog(text,SRCTYPE_PER)) });
    this.getData(doubanRSS , (text)=>{ this.joinRecentList( this.parseDouban(text,SRCTYPE_DOUBAN)) });
    this.getData(tweiboHTML , (text)=>{ this.joinRecentList( this.parseTWeibo(text,SRCTYPE_TWEIBO)) });
  }

  getData( url , cb ){
    fetch(GETFILE_PROXY+ url )
      .then(function(response){
      return response.text();})
      .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
      .then( (xmlRet)=> {
        //fetch succ
        cb ( xmlRet );
      })
      .catch((error) => {
      console.error(error);
    })
  }

  recentList = [];
  joinRecentList( listPart )
  {

    this.recentList = this.recentList.concat(listPart);
    //排序
    this.recentList.sort(this.sortByDate);
    //派发通知

    Facade.sendNotify(Notify.MOMENTS_LIST_CHANGED , this , this.recentList);

  }

  sortByDate(a, b)
  {
    var at = a.date.valueOf();
    var bt = b.date.valueOf();
    if (at < bt)return 1;
    else if (at > bt) return -1;
    else
      return 0;
  }


  parseBlog(xml, srcType)
  {
    var end = [];
    var channel = xml.getElementsByTagName('channel')[0];
    var items = channel.getElementsByTagName('item');
    for (var i = 0; i < items.length; i++)
    {
      var vo = {};
      vo.type = srcType;
      vo.key= `${srcType}-${i}`;
      vo.title = (items[i].getElementsByTagName('title')[0].firstChild.nodeValue);
      vo.link = (items[i].getElementsByTagName('link')[0].firstChild.nodeValue);
      vo.date = new Date(items[i].getElementsByTagName('pubDate')[0].firstChild.nodeValue);
      try
      {
        var content;
        if (items[i].getElementsByTagName('encoded').length > 0)
        {
          content = items[i].getElementsByTagName('encoded')[0].firstChild.nodeValue;
        } else
        {
          content = items[i].getElementsByTagName('content:encoded')[0].firstChild.nodeValue;
        }
        var hasImgTag = content.indexOf('<img src="');
        if (hasImgTag != -1)
        {
          var imgTagStart = hasImgTag + 10;
          // 有图片
          var imgSrc = content.substring(imgTagStart, content.indexOf('" alt="', imgTagStart));
          vo.img = imgSrc;
        } else
        {
          vo.img = '';
        }
      } catch (e)
      {
//            trace(e);
        vo.img = '';
      }
      //content 带图片的html
      //description 文字
      let blogDesc = items[i].getElementsByTagName("description")[0].firstChild.nodeValue;
      // 存在特殊转译   使用RN出这个错误，经检查有问题的代码是： [crayon-5b25325d17844749436449/]  [&#8230;]
      let indexOf = blogDesc.indexOf("[crayon");
      if ( indexOf != -1 ) blogDesc = blogDesc.substr(0,indexOf)+"... ";
      indexOf = blogDesc.indexOf("[&#");
      if ( indexOf != -1 ) blogDesc = blogDesc.substr(0,indexOf)+"... ";
      vo.intro = [blogDesc];
      end.push(vo);
    }
    return end;
  }

  parseDouban(xml)
  {
    var end = [];
    var channel = xml.getElementsByTagName('channel')[0];
    var items = channel.getElementsByTagName('item');
    for (var i = 0; i < items.length; i++)
    {
      var vo = {};
      vo.type = SRCTYPE_DOUBAN;
      vo.key= `${vo.type}-${i}`;
      var title = (items[i].getElementsByTagName('title')[0].firstChild.nodeValue);
      var desc = items[i].getElementsByTagName('description')[0].firstChild.nodeValue;
      var img = desc.substring(desc.indexOf('<img src="'), desc.indexOf('</a></td>'));
      var tags = desc.substring(desc.indexOf('<p>') + 3, desc.lastIndexOf('</p>'));
      // tags中的分段符 改成br
      tags = tags.replace(/<\/p>[\s\n\r]*<p>/ig, "\n");
      vo.title = title;
      vo.link = (items[i].getElementsByTagName('link')[0].firstChild.nodeValue);
      vo.date = new Date(items[i].getElementsByTagName('pubDate')[0].firstChild.nodeValue);
      var imgSrc = img.substring(img.indexOf('<img src="') + 10, img.indexOf('" alt="'));
      vo.img = imgSrc;
      vo.intro  = tags;
      vo.intro = vo.intro.split("\n");
      end.push(vo);
    }
    return end;
  }
    parseTWeibo(text) {
  var end = [];
  var liExp = /\<li style=\"display:block;overflow:hidden;\"\><a href=\"([^\"]*)\" target=\"_blank\">(.*)<\/li>/gi;
  var aLinkExp = /<a href="[^<]*<\/a>/i
  var liDateExp = /<span class="l">(.*)<\/span>/i;
  var imgExp = /<p class=\"imgshow\"><img src=\"(.*)\" \/>/i;
  var i =0;
  var li;
  while(li  = liExp.exec(text))
  {
    var vo = {};
    vo.type = SRCTYPE_TWEIBO;
    vo.key= `${vo.type}-${i}`;
    //  微博文字内容  <=踢出后面的intro
    var title =li[2].substr(0,li[2].indexOf('</a><'));
    // 如果有链接，移到末尾
    let hasLink = null;
    if(hasLink=aLinkExp.exec(title))
    {
      vo.title = title.split(hasLink).join("") + " "+hasLink;
    }
    else
      vo.title = title;
    // 微博链接
    vo.link = li[1];
    // 日期
    let dateRes = null;
    if(dateRes = liDateExp.exec(li[2]))
      vo.date = this.parseDdFromTWeibo(dateRes[1]);
    // 图片
    let img=null;
    if(img=imgExp.exec(li[2]))
      vo.img = img[1];
    else
      vo.img = null;
    // 过滤掉豆瓣同步过去的 TODO

    end.push(vo);
  }
  return end;
}
    parseDdFromTWeibo(txt) {
  // %d分钟前 来自%s
  // 昨天 %2d:%2d 来自%s
  // %d月%d日 %2d:%2d 来自%s
  var end = new Date();

  var endStr = txt.indexOf('来自');
  var dtStr = txt.substring(0, endStr);

  try {
    var rxpMonDate = /([0-9]{1,2})月([0-9]{1,2})日/ig;
    var mdr = rxpMonDate.exec(dtStr);
    if (mdr != null) {
      end.setMonth(mdr[1] - 1, mdr[2]);
      //月日搞定，再检测是否有年份
      var rxpYearMonDate = /([0-9]{4})年/ig;
      var yr=rxpYearMonDate.exec(dtStr);
      if(yr != null)
      {
        end.setFullYear(yr[1]);
      }
    } else {
      if (dtStr.indexOf('昨天') != -1) {
        end.setTime(end.valueOf() - 24 * 60 * 60 * 1000);
      }
    }

    var rxpTime = /([0-9]{2}):([0-9]{2})/g;
    var tr = rxpTime.exec(dtStr);
    if (tr != null) {
      end.setHours(tr[1], tr[2]);
    } else {
      // 没有%2d:%2d 检查是否有%d分钟前
      var rxpTime1 = /([0-9]{1,2})分钟前/g;
      var tr1 = rxpTime1.exec(dtStr);
      if (tr1 != null) {
        end.setTime(end.valueOf() - 60000 * tr1[1]);
      }
    }


  } catch (e) {
    console.log("MomentsModel/parseDdFromTWeibo",e);
  }
  return end;
}
}