
import React, {Component} from 'react';
import "./VideoBg.css";

export default class VideoBg extends Component
{


  componentDidMount()
  {
  }
  render(){
    return (
      <div className="VideoBg">

        <video className="v1" autoPlay="true" autoplay="true" muted="true" loop="true" webkit-playsinline="true"  playsinline="true">
          <source src={require("./videos/web-bgvid.mp4")} />
        </video>

      </div>);
  }

}