
import {
  DataTexture,
  FloatType,
  Math as _Math,
  RGBFormat,
  ShaderMaterial,
  UniformsUtils
} from "three";
import { Pass } from "three/examples/jsm/postprocessing/Pass.js";
import { DigitalGlitch } from "three/examples/jsm/shaders/DigitalGlitch.js";

var MBGlitchPass = function ( delay,duration,dt_size ) {

  this.delay = delay;
  if (delay ==undefined ) this.delay= 600;//_Math.randInt( 360, 440 );
  this.duration = duration;
  if(duration ==undefined) this.duration = 30;


  Pass.call( this );

  if ( DigitalGlitch === undefined ) console.error( "MBGlitchPass relies on DigitalGlitch" );

  var shader = DigitalGlitch;
  this.uniforms = UniformsUtils.clone( shader.uniforms );

  if ( dt_size == undefined ) dt_size = 64;


  this.uniforms[ "tDisp" ].value = this.generateHeightmap( dt_size );


  this.material = new ShaderMaterial( {
    uniforms: this.uniforms,
    vertexShader: shader.vertexShader,
    fragmentShader: shader.fragmentShader
  } );

  this.fsQuad = new Pass.FullScreenQuad( this.material );

  this.goWild = false;
  this.curF = this.duration+1;
  this.generateTrigger();

};

MBGlitchPass.prototype = Object.assign( Object.create( Pass.prototype ), {

  constructor: MBGlitchPass,

  render: function ( renderer, writeBuffer, readBuffer /*, deltaTime, maskActive */ ) {

    this.uniforms[ "tDiffuse" ].value = readBuffer.texture;
    this.uniforms[ 'seed' ].value = Math.random();//default seeding
    this.uniforms[ 'byp' ].value = 0;

    if ( this.curF % this.randX == 0 || this.goWild == true ) {
      console.log("Pass/render randX==0");

      this.uniforms[ 'amount' ].value = Math.random() / 30;
      this.uniforms[ 'angle' ].value = _Math.randFloat( - Math.PI, Math.PI );
      this.uniforms[ 'seed_x' ].value = _Math.randFloat( - 1, 1 );
      this.uniforms[ 'seed_y' ].value = _Math.randFloat( - 1, 1 );
      this.uniforms[ 'distortion_x' ].value = _Math.randFloat( 0, 1 );
      this.uniforms[ 'distortion_y' ].value = _Math.randFloat( 0, 1 );
      this.curF = 0;
      this.generateTrigger();

    } else if ( this.curF % this.randX < this.duration ) {
      this.uniforms[ 'amount' ].value = Math.random() / 90;
      this.uniforms[ 'angle' ].value = _Math.randFloat( - Math.PI, Math.PI );
      this.uniforms[ 'distortion_x' ].value = _Math.randFloat( 0, 1 );
      this.uniforms[ 'distortion_y' ].value = _Math.randFloat( 0, 1 );
      this.uniforms[ 'seed_x' ].value = _Math.randFloat( - 0.3, 0.3 );
      this.uniforms[ 'seed_y' ].value = _Math.randFloat( - 0.3, 0.3 );

    } else if ( this.goWild == false ) {

      this.uniforms[ 'byp' ].value = 1;

    }

    this.curF ++;

    if ( this.renderToScreen ) {

      renderer.setRenderTarget( null );
      this.fsQuad.render( renderer );

    } else {

      renderer.setRenderTarget( writeBuffer );
      if ( this.clear ) renderer.clear();
      this.fsQuad.render( renderer );

    }

  },

  generateTrigger: function () {

    this.randX = this.delay;

  },

  generateHeightmap: function ( dt_size ) {

    var data_arr = new Float32Array( dt_size * dt_size * 3 );
    var length = dt_size * dt_size;

    for ( var i = 0; i < length; i ++ ) {

      var val = _Math.randFloat( 0, 1 );
      data_arr[ i * 3 + 0 ] = val;
      data_arr[ i * 3 + 1 ] = val;
      data_arr[ i * 3 + 2 ] = val;

    }

    var texture = new DataTexture( data_arr, dt_size, dt_size, RGBFormat, FloatType );
    texture.needsUpdate = true;
    return texture;

  }

} );

export { MBGlitchPass };
