/**
 * Created by rhett on 2018/6/16.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import App from "./App";
import "./MainPage.css";
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import Facade from "./Facade";
import Notify from "./Notify";
import MBHeader from "./MBHeader";
import "./ProjectsPage.css";
import VideoPlayer from "./VideoPlayer";


export default class ProjectsPage extends Component
{
  static propTypes = {
    //height :PropTypes.number,//高度
  };

  constructor(props)
  {
    super(props);
    this.state = {};
  }


  componentWillUnmount()
  {

  }

  componentWillMount()
  {
  }

  componentDidMount()
  {
  }


  render()
  {
    return (
      <div className="ProjectsPage  ">


        <div className="grid">

          <ProjectWrapper
            title="符文要塞"
            category="单机游戏"
            year="2021"
            roundIco={false}
            imgSrc={require("./img/projects/FortressSeed.png")}>
            <p>
              死守要塞，撑住30天。每天夜晚会有怪物攻城。
            </p>
            <p>
              玩家需要在夜晚来临之前做足准备。搜集土地上的资源和难民，建造和加固自己的要塞，修建更多的城墙塔楼，研究不同的科技，尝试不同发展方向。
            </p>
            <p>
              攻城来临时，准备好强大的魔法，弓箭手和投石车在要塞内的塔楼据守。迎接壮烈的怪物攻城。
            </p>
            <p>
              守护符文要塞，为了世界上最后的魔法圣地！
            </p>
            <p>
              ### 游戏特色：

              壮丽的战斗场面，

              简单易玩，

              移动捕获资源，

              升级科技加点。

              纯单机游戏，

              无任何内购氪金。
            </p>
            {/*<p>
              <a target="_blank" href="https://apps.apple.com/cn/app/%E7%AC%A6%E6%96%87%E8%A6%81%E5%A1%9E/id1587807851?mt=12"><i className="fa fa-apple"></i>Mac App Store</a>
            </p>*/}
          </ProjectWrapper>

          <ProjectWrapper
            title="兵海大战"
            category="手机游戏"
            year="2020"
            imgSrc={require("./img/projects/ArmySeed.png")}>
            <p>
              史诗3D兵海大战，招募和强化我们人类的部队来对抗来袭的半兽人大军。
            </p>
            <p>
              这是一场旷日持久的战斗，随着战事不断升级，双方都将倾全世界之兵而出，除了兵种越来越丰富，战力越来越强大，双方后方的援军也在不断抵达，我们绝不能失败，为了人类！
            </p>
            <p>
              玩家扮演的人类方，从刚开始小规模遭遇敌军开始，不断增加部署、不断升级强化、不断派驻新部队、不断增加援军以及逐渐加快援军抵达时机，来一次次尝试击败来犯的半兽人军队。
            </p>
            <p>
              这是一款快节奏的战斗游戏，玩家不需要高超的微操，只需要选择科技+排兵布阵后让双方全自动作战即可观赏壮观的全程。
            </p>
            <p>
              除了步兵以外，玩家可以逐渐解锁弓箭手、牧师、轻骑兵、法师、重骑兵、燃火投石车搭配部署。
            </p>
            <p>
              每场战斗之后可以利用战利品解锁或升级新兵种和科技。
            </p>
            <p>
              不断强化和解锁部署范围，打造壮观的兵海大战场面和丰厚的战利品收入。
            </p>
            <p>
              ### 游戏特色：

              壮丽的战斗场面，

              简单易玩，

              策略排兵布阵，

              升级科技加点。

              纯单机游戏，

              免费玩，无任何扣费，
            </p>
            {/*<p>
              <a target="_blank" href="https://apps.apple.com/cn/app/%E5%85%B5%E6%B5%B7%E5%A4%A7%E6%88%98/id1559357744"><i className="fa fa-apple"></i> App Store</a>
              |
              <a target="_blank" href="https://play.google.com/store/apps/details?id=org.mousebomb.armyseed"><i className="fa fa-android"></i> Google Play</a>
            </p>*/}
          </ProjectWrapper>

          <ProjectWrapper
            title="开心饭店"
            category="手机游戏"
            year="2016"
            imgSrc={require("./img/projects/SimResturant.png")}>
            <p>
              开心饭店是一款策略为主的模拟经营游戏，靠你的聪明智慧，将一家小饭店逐步做大。带来壮观的客流量和丰厚的收入！
              不同于大多数游戏，在这里，你是老板你做主，你不需要亲力亲为，你只要做决策。员工会按照你的部署搞定每天的正常营业！
            </p>
            <p>
              行人在外面路过，有几率成为顾客进入店铺消费。厨师够棒，迎宾够美，自然顾客多多啦。
            </p>
            <p>
              顾客的满意度至关重要，每个顾客的耐心都是有限的，得让顾客尽快受到很好的服务，一个跑得快的服务员必不可少，厨艺高超的厨师也会让顾客更满意。满意的顾客不仅付钱，还会产生人气，人气高了饭店才能越扩越大噢！
            </p>
            <p>
              你的员工各自才干不同，敏捷高的人适合做服务员，厨艺好的适合做厨师，计算好的适合做收银员，魅力高的适合做迎宾。
              店里四种职业各司其职、互相配合，服务顾客。雇佣到合适的人才，你就等着数钱吧！
            </p>
            <p>
              除了核心的厨师服务员收银员迎宾互相配合的职业系统、菜式升级树、采购食材、招聘伙计、员工管理、店内道具、店铺装潢、店铺扩建等功能外，试想，送给厨师一本《膳食宝典》，不仅可以讨好厨师的忠心，还加厨艺点数多有趣？
            </p>


            {/*<p>
              <a target="_blank" href="http://www.mousebomb.org/app/simresturant/"><i className="fa fa-link"></i> 专题页面</a>
              |
              <a target="_blank" href="https://itunes.apple.com/us/app/kai-xin-fan-dian/id1109983638?l=zh&ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>
              |
              <a target="_blank" href="https://play.google.com/store/apps/details?id=org.mousebomb.simresturant"><i className="fa fa-android"></i> Google Play</a>
              |
              <a target="_blank" href="http://dn-mousebomb.qbox.me/app/simresturant/simrestaurant.apk"><i className="fa fa-download"></i> apk安装包</a>

            </p>*/}
          </ProjectWrapper>

          <ProjectWrapper
            title="南方熊少儿除法"
            category="手机游戏/平板电脑游戏"
            year="2014"
            imgSrc={require("./img/projects/MathRaceDivision.png")}>
            <p>南方熊算术练习系列 - 开动脑筋，算术练习，开发智力。        </p><p>
            南方熊少儿除法，让宝宝学习除法，家长可以每天查看孩子做算术题的结果，会自动记录最近一个月的每日成绩噢！
          </p><p>
            游戏以小鸟的飞行比赛的形式，让孩子练习加法，计算越快，小鸟就飞得越快，每次玩一组加法测验，回答错误的题的答案将会被列出，完成比赛会获得名次，如果获得第一名还会有宠物的奖励噢。不同难度会有不同奖励，总共八种宠物。
          </p><p>
            寓教于乐，宝宝玩游戏收集宠物，家长看孩子的答题数据。
          </p><p>
            本app会记录孩子最近一个月的每日成绩，记录答题时间、准确率、总答题数和答题速度。
          </p>

            {/*<p>
              <a target="_blank" href="https://itunes.apple.com/cn/app/nan-fang-xiong-shao-er-chu/id889013346?l=zh&ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>
              |
              <a target="_blank" href="https://play.google.com/store/apps/details?id=air.org.mousebomb.game37"><i className="fa fa-android"></i> Google Play</a>
              |
              <a target="_blank" href="http://app.xiaomi.com/detail/64313"><i className="fa fa-android"></i> 小米</a>
              |
              <a target="_blank" href="http://www.wandoujia.com/apps/air.org.mousebomb.game37"><i className="fa fa-android"></i> 豌豆荚</a>
              |
              <a target="_blank" href="http://zhushou.360.cn/detail/index/soft_id/1796469"><i className="fa fa-android"></i> 360手机助手</a>
              |
              <a target="_blank" href="http://mousebomb-public.qiniudn.com/MathRaceDivision_qiniu.apk"><i className="fa fa-download"></i> apk安装包</a>
            </p>*/}
          </ProjectWrapper>

          <ProjectWrapper
            title="南方熊少儿乘法"
            category="手机游戏/平板电脑游戏"
            year="2014"
            imgSrc={require("./img/projects/MathRaceMultiply.png")}>
            <p>南方熊算术练习系列 - 开动脑筋，算术练习，开发智力。        </p><p>
            南方熊少儿乘法，让宝宝学习乘法，家长可以每天查看孩子做算术题的结果，会自动记录最近一个月的每日成绩噢！
          </p><p>
            游戏以小鸟的飞行比赛的形式，让孩子练习加法，计算越快，小鸟就飞得越快，每次玩一组加法测验，回答错误的题的答案将会被列出，完成比赛会获得名次，如果获得第一名还会有宠物的奖励噢。不同难度会有不同奖励，总共八种宠物。
          </p><p>
            寓教于乐，宝宝玩游戏收集宠物，家长看孩子的答题数据。
          </p><p>
            本app会记录孩子最近一个月的每日成绩，记录答题时间、准确率、总答题数和答题速度。
          </p>

            {/*<p>*/}
            {/*  <a target="_blank" href="https://itunes.apple.com/cn/app/nan-fang-xiong-shao-er-cheng/id888994850?l=zh&ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://app.xiaomi.com/detail/64312"><i className="fa fa-android"></i> 小米</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://www.wandoujia.com/apps/air.org.mousebomb.game36"><i className="fa fa-android"></i> 豌豆荚</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://zhushou.360.cn/detail/index/soft_id/1796459"><i className="fa fa-android"></i> 360手机助手</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://mousebomb-public.qiniudn.com/MathRaceMultiply_qiniu.apk"><i className="fa fa-download"></i> apk安装包</a>*/}
            {/*</p>*/}
          </ProjectWrapper>

          <ProjectWrapper
            title="南方熊少儿减法"
            category="手机游戏/平板电脑游戏"
            year="2014"
            imgSrc={require("./img/projects/MathRaceMinus.png")}>
            <p>南方熊算术练习系列 - 开动脑筋，算术练习，开发智力。        </p><p>
            南方熊少儿减法，让宝宝学习减法，家长可以每天查看孩子做算术题的结果，会自动记录最近一个月的每日成绩噢！
          </p><p>
            游戏以小鸟的飞行比赛的形式，让孩子练习加法，计算越快，小鸟就飞得越快，每次玩一组加法测验，回答错误的题的答案将会被列出，完成比赛会获得名次，如果获得第一名还会有宠物的奖励噢。不同难度会有不同奖励，总共八种宠物。
          </p><p>
            寓教于乐，宝宝玩游戏收集宠物，家长看孩子的答题数据。
          </p><p>
            本app会记录孩子最近一个月的每日成绩，记录答题时间、准确率、总答题数和答题速度。
          </p>

            {/*<p>*/}
            {/*  <a target="_blank" href="https://itunes.apple.com/cn/app/nan-fang-xiong-shao-er-jian/id888656820?l=zh&ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://app.xiaomi.com/detail/64302"><i className="fa fa-android"></i> 小米</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://zhushou.360.cn/detail/index/soft_id/1796460?recrefer=SE_D_南方熊"><i className="fa fa-android"></i> 360手机助手</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://www.wandoujia.com/apps/air.org.mousebomb.game35"><i className="fa fa-android"></i> 豌豆荚</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://mousebomb-public.qiniudn.com/MathRaceMinus_qiniu.apk"><i className="fa fa-download"></i> apk安装包</a>*/}
            {/*</p>*/}
          </ProjectWrapper>

          <ProjectWrapper
            title="南方熊少儿加法"
            category="手机游戏/平板电脑游戏"
            year="2014"
            imgSrc={require("./img/projects/MathRace.png")}>

            <p>南方熊算术练习系列 - 开动脑筋，算术练习，开发智力。        </p><p>
            南方熊少儿加法，让宝宝学习加法，家长可以每天查看孩子做算术题的结果，会自动记录最近一个月的每日成绩噢！
          </p><p>
            游戏以小鸟的飞行比赛的形式，让孩子练习加法，计算越快，小鸟就飞得越快，每次玩一组加法测验，回答错误的题的答案将会被列出，完成比赛会获得名次，如果获得第一名还会有宠物的奖励噢。不同难度会有不同奖励，总共八种宠物。
          </p><p>
            寓教于乐，宝宝玩游戏收集宠物，家长看孩子的答题数据。
          </p><p>
            本app会记录孩子最近一个月的每日成绩，记录答题时间、准确率、总答题数和答题速度。
          </p>

            {/*<p>*/}
            {/*  <a target="_blank" href="https://itunes.apple.com/cn/app/nan-fang-xiong-shao-er-jia/id887425917?l=zh&ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://app.xiaomi.com/detail/63957"><i className="fa fa-android"></i> 小米</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://zhushou.360.cn/detail/index/soft_id/1790361"><i className="fa fa-android"></i> 360手机助手</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://mousebomb-public.qiniudn.com/MathRace_qiniu.apk"><i className="fa fa-download"></i> apk安装包</a>*/}
            {/*</p>*/}
          </ProjectWrapper>

          <ProjectWrapper
            title="南方熊填色2:海洋篇"
            category="手机游戏/平板电脑游戏"
            year="2014"
            imgSrc={require("./img/projects/PainterSea.png")}>
            <p>这次，南方熊带小朋友来描绘海洋生物啦。</p>
            <p>填色系列游戏，开发宝宝的右脑智慧，培养宝宝的创造力和宝宝的想像力。 </p><p>
            可爱的卡通形象，认识动物，认识颜色，让宝宝酷爱填色。
          </p><p>
            本填色游戏包含24种动物，28种颜色，每个都带真人读音。
          </p><p>
            玩法：画面右侧是调色盘，点选颜色，点击图上的色块，即可填上选中的颜色。
          </p>

            {/*<p>*/}
            {/*  <a target="_blank" href="https://itunes.apple.com/cn/app/nan-fang-xiong-shao-er-tian/id887426875?l=zh&ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://zhushou.360.cn/detail/index/soft_id/1789339"><i className="fa fa-android"></i> 360手机助手</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://app.xiaomi.com/detail/63954"><i className="fa fa-android"></i> 小米</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://mousebomb-public.qiniudn.com/PainterSea_qiniu.apk"><i className="fa fa-download"></i> apk安装包</a>*/}
            {/*</p>*/}
          </ProjectWrapper>

          <ProjectWrapper
            title="少儿填色之十二生肖"
            category="手机游戏/平板电脑游戏"
            year="2014"
            imgSrc={require("./img/projects/ChineseZodiacPainter.png")}>

            <p>少儿填色，十二生肖填色。</p>
            <p>填色系列游戏，开发宝宝的右脑智慧，培养宝宝的创造力和宝宝的想像力。 </p><p>
            可爱的卡通形象，认识动物，认识颜色，让宝宝酷爱填色。
          </p><p>
            真人配音，认识中华传统十二生肖，又了解颜色，还能教孩子听和读。
          </p><p>
            玩法：画面下方是调色盘，点选颜色，点击图上的色块，即可填上选中的颜色。
          </p>

            {/*<p>*/}
            {/*  <a target="_blank" href="https://itunes.apple.com/cn/app/shao-er-tian-se-zhi-shi-er/id884665157?l=zh&ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://app.xiaomi.com/detail/63310"><i className="fa fa-android"></i> 小米</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://zhushou.360.cn/detail/index/soft_id/1780063"><i className="fa fa-android"></i> 360手机助手</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://www.wandoujia.com/apps/air.com.aoaogame.game23"><i className="fa fa-android"></i> 豌豆荚</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://mousebomb-public.qiniudn.com/ChineseZodiacPainter.apk"><i className="fa fa-download"></i> apk安装包</a>*/}
            {/*</p>*/}

          </ProjectWrapper>

          <ProjectWrapper
            title="符文三消大师"
            category="手机游戏"
            year="2014"
            imgSrc={require("./img/projects/SanXiaoFW.png")}>

            <p>你是一个牛逼闪闪的符文魔法师。每天跟各种符文打交道，给他们建立秩序。
            </p>

            <p>

              游戏玩法：</p>
            <ul>
              <li>消除三个或以上相同的符文。</li>
              <li>4个相同的符文连成一线则获得光华符文，当它消除时会消掉周围九个。</li>
              <li>横竖两个方向同时有3个符文练成线，会获得闪闪发光的符文，当它被消除时横竖两条都会消除掉。</li>
              <li>5个符文连成一线时，获得神奇的任意符，它可以跟任何符文交换，场上所有同类符文都会变身成这个符文，一起消除。好爽快。</li>
            </ul>

            {/*<p>*/}
            {/*  <a target="_blank" href="https://itunes.apple.com/cn/app/fu-wen-san-xiao-da-shi/id882123296?l=zh&ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://app.xiaomi.com/detail/62563"><i className="fa fa-android"></i> 小米</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://mousebomb-public.qiniudn.com/SanXiaoFW.apk"><i className="fa fa-download"></i> apk安装包</a>*/}
            {/*</p>*/}

          </ProjectWrapper>

          <ProjectWrapper
            title="南方熊少儿填色"
            category="手机游戏"
            year="2014"
            imgSrc={require("./img/projects/PainterNS.png")}>

            <p>填色系列游戏，开发宝宝的右脑智慧，培养宝宝的创造力和宝宝的想像力。 </p><p>
            可爱的卡通形象，认识动物，认识颜色，让宝宝酷爱填色。
          </p><p>
            本填色游戏包含24种动物，28种颜色，每个都带真人读音。
          </p><p>
            玩法：画面右侧是调色盘，点选颜色，点击图上的色块，即可填上选中的颜色。
          </p>

            {/*<p>*/}
            {/*  <a target="_blank" href="https://itunes.apple.com/cn/app/nan-fang-xiong-shao-er-tian/id881709008?l=zh&ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://app.xiaomi.com/detail/62739"><i className="fa fa-android"></i> 小米</a>*/}
            {/*  |*/}
            {/*  <a target="_blank" href="http://mousebomb-public.qiniudn.com/PainterNS.apk"><i className="fa fa-download"></i> apk安装包</a>*/}
            {/*</p>*/}
          </ProjectWrapper>

          <ProjectWrapper
            title="宝宝成长宝典"
            category="iOS App"
            year="2014"
            imgSrc={require("./img/projects/BabyGrow.png")}>
            <p>伴随0～6岁的宝宝成长。1～36个月，每个月详细讲解。3～6周岁一岁一长篇。
            </p>


            <ul>
              <li>每个时期如何带宝宝？</li>
              <li>有哪些预防疾病的注意事项？</li>
              <li>如何照料营养？吃什么食物会导致孩子不长高？</li>
              <li>怎样教育？惹恼家长时如何正确处理？</li>
              <li>怎样良好地培养孩子肢体协调？</li>
              <li>……</li>
            </ul>

            <p>
              本宝典内容丰富，信息时代的父母值得人手拥有一册！
            </p>

            <p>
              每个时期都从生理发育、疾病、营养、教育、亲子游戏 五大角度全面支持父母带好宝宝。助您的宝宝健康成长！</p>


            {/*<p><a href="https://itunes.apple.com/cn/app/bao-bao-cheng-zhang-bao-dian/id878840399?l=zh&ls=1&mt=8"*/}
            {/*      target="_blank"><i className="fa fa-apple"></i> 去App Store下载</a>*/}
            {/*  |*/}
            {/*  <a href="https://play.google.com/store/apps/details?id=air.org.mousebomb.BabyGrow"*/}
            {/*     target="_blank"><i className="fa fa-apple"></i> 去Google Play下载</a></p>*/}

          </ProjectWrapper>

          <ProjectWrapper
            title="少儿填色"
            category="手机游戏"
            year="2014"
            imgSrc={require("./img/projects/Painter.png")}>

            <p>填色系列游戏，开发宝宝的右脑智慧，培养宝宝的创造力和宝宝的想像力。 </p><p>
            可爱的卡通形象，让宝宝酷爱填色。
          </p><p>
            玩法：画面下方是调色盘，点选颜色，点击图上的色块，即可填上选中的颜色。
          </p>

            {/*<p>
              <a target="_blank" href="http://app.xiaomi.com/detail/60983"><i className="fa fa-android"></i> 小米</a>
              |
              <a target="_blank" href="http://www.wandoujia.com/apps/air.com.aoaogame.game20"><i className="fa fa-android"></i> 豌豆荚</a>
              |
              <a target="_blank" href="http://zhushou.360.cn/detail/index/soft_id/1745120"><i className="fa fa-android"></i> 360手机助手</a>
              |
              <a target="_blank" href="http://as.baidu.com/a/item?docid=6549880"><i className="fa fa-android"></i> 百度手机助手</a>
              |
              <a target="_blank" href="http://mousebomb-public.qiniudn.com/Painter_qiniu.apk"><i className="fa fa-download"></i> apk安装包</a>
            </p>*/}
          </ProjectWrapper>

          <ProjectWrapper
            title="水果消消乐"
            category="手机游戏"
            year="2014"
            imgSrc={require("./img/projects/FruitSX.png")}>

            <p>清凉夏天要来了，来玩水果三消吧！
            </p>

            <p>

              游戏玩法：</p>
            <ul>
              <li>消除三个或以上相同的水果。</li>
              <li>4个相同的水果连成一线则获得光华水果，当它消除时会爆破周围九个。</li>
              <li>横竖两个方向同时有3个水果练成线，会获得闪闪发光的水果，当它被消除时横竖两条都会爆掉。</li>
              <li>5个水果连成一线时，获得神奇的百变果。百变果可以跟任意食物交换，场上所有同类水果都会变身成这个水果，一起消除。好爽快。</li>
            </ul>

            {/*<p>
              <a target="_blank" href="https://itunes.apple.com/cn/app/shui-guo-xiao-xiao-le-mei/id871058345?ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>
              |
              <a target="_blank" href="https://play.google.com/store/apps/details?id=org.mousebomb.game61"><i className="fa fa-android"></i> Google Play</a>
              |
              <a target="_blank" href="http://app.xiaomi.com/detail/61057"><i className="fa fa-android"></i> 小米</a>
              |
              <a target="_blank" href="http://mousebomb-public.qiniudn.com/FruitSX.apk"><i className="fa fa-download"></i> apk安装包</a>
            </p>*/}

          </ProjectWrapper>

          <ProjectWrapper
            title="丛林消消乐"
            category="手机游戏"
            year="2014"
            imgSrc={require("./img/projects/JungleSanXiao.png")}>
            <p>丛林风格三消游戏，美丽绚烂的画风加童趣的声音，老少皆宜。
            </p>

            <p>

              游戏玩法：</p>
            <ul>
              <li>消除三个或以上相同的动物。</li>
              <li>4个相同的动物连成一线则获得热情动物，当它消除时会消掉周围九个。</li>
              <li>横竖两个方向同时有3个动物连成线，会获得吼吼动物，当它被消除时横竖两条都会消除掉。</li>
              <li>5个动物连成一线时，获得神奇的百变兽，它可以跟任意动物交换，场上所有同类动物都会变身成这个动物，一起消除。好爽快。</li>
              <li>带着小孩子，玩游戏的同时学英语单词。</li>
            </ul>

            {/*<p>
              <a target="_blank" href="https://itunes.apple.com/cn/app/cong-lin-xiao-xiao-le/id867006983?ls=1&mt=8"><i
                className="fa fa-apple"/> App Store</a>
              |
              <a target="_blank" href="http://app.xiaomi.com/detail/60599"><i className="fa fa-android"/> 小米</a>
              |
              <a target="_blank" href="http://mousebomb-public.qiniudn.com/JungleSanXiao.apk"><i
                className="fa fa-download"/> apk安装包</a>
            </p>*/}
          </ProjectWrapper>

          <ProjectWrapper
            title="太空战机杀手号"
            category="手机游戏"
            year="2014"
            imgSrc={require("./img/projects/JetKill.png")}>

            <p>太空战机杀手号让你可以控制一艘在浩瀚星空中自由的飞船，对抗敌机并升级和武装自己。</p>
            <p>单手触摸控制飞行方向，全自动射击，操作简单，但随着击败敌机，游戏难度将越来越难噢！躲避敌人的射击，干掉敌机，是男人就在这游戏里撑100秒！</p>
            <p>被消灭的敌人会掉落道具，捡起各种道具将会获得新的武器、防护罩、引擎超速、机体升级、回复生命、复活等各种能力。助你在跟每秒都在变得更强大的敌机对战中永远占据优势……</p>



            {/*<p>
              <a className="btn-show-video" href="javascript:;" onClick={()=>{VideoPlayer.showVideo("JetKill.mp4")}} ><i className="fa fa-video-camera"></i> 观看视频</a>
              |
              <a target="_blank" href="https://itunes.apple.com/cn/app/tai-kong-zhan-ji-sha-shou/id858558882?ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>
              |
              <a target="_blank" href="http://app.xiaomi.com/detail/60314"><i className="fa fa-android"></i> 小米</a>
              |
              <a target="_blank" href="http://mousebomb-public.qiniudn.com/JetKill.apk"><i className="fa fa-download"></i> apk安装包</a>

            </p>*/}

          </ProjectWrapper>

          <ProjectWrapper
            title="小鸟消消乐-里约版"
            category="手机游戏"
            year="2014"
            imgSrc={require("./img/projects/BirdRioSanXiao.png")}>

            <p>魅力里约，小鸟的世界，快来玩消消乐。
            </p>

            <p>

              游戏玩法：</p>
            <ul>
              <li>消除三个或以上相同的小鸟</li>
              <li>4个相同的小鸟连成一线则获得愤怒小鸟，当它消除时会消掉周围九个。
              </li>
              <li>横竖两个方向同时有3个小鸟练成线，会获得十字章小鸟，当它被消除时横竖两条都会消除掉。
              </li>
              <li>5个小鸟连成一线时，获得神奇的鸟蛋，它可以跟任意小鸟交换，场上所有同类小鸟都会变身成这个小鸟，一起消除。好爽快。
              </li>
              <li>闯关模式 和 无限模式，尽享三消乐趣。</li>
            </ul>

            {/*<p>
              <a target="_blank" href="https://itunes.apple.com/cn/app/xiao-niao-xiao-xiao-le-li/id853746664?ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>
              |
              <a target="_blank" href="http://app.xiaomi.com/detail/59536"><i className="fa fa-android"></i> 小米</a>
              |
              <a target="_blank" href="http://mousebomb-public.qiniudn.com/BirdRioSanXiaoA.apk"><i className="fa fa-download"></i> apk安装包</a>

            </p>*/}

          </ProjectWrapper>

          <ProjectWrapper
            title="来自星星的美食约会"
            category="手机游戏"
            year="2014"
            imgSrc={require("./img/projects/DinnerFromStar.png")}>

            <p>都教授消失了，美貌与智慧并存的你通过美食三消不断获得能量，增加他来到你身边停留的时间，与你来一场美食约会。都敏俊兮，我在等你，你知道吗？都敏俊兮，我一直在努力，你听得到吗？
            </p>

            <p>

              游戏玩法：</p>
            <ul>
              <li>消除三个或以上相同的美食</li>
              <li>4个相同的美食连成一线则获得加辣食物，当它消除时会爆破周围九个。</li>
              <li>横竖两个方向同时有3个美食练成线，会获得十字章食物，当它被消除时横竖两条都会爆掉。</li>
              <li>5个美食连成一线时，获得神奇的棒棒糖。棒棒糖可以跟任意食物交换，场上所有同类食物都会变身成这个食物，一起爆炸。好爽快。</li>
              <li>闯关模式 和 无限模式，尽享三消乐趣。</li>
            </ul>

            {/*<p>
              <a target="_blank" href="https://itunes.apple.com/us/app/xing-xing-mei-shi-yue-hui/id841617885?ls=1&mt=8"><i className="fa fa-apple"></i> App Store</a>
              |
              <a target="_blank" href="http://app.xiaomi.com/detail/60315"><i className="fa fa-android"></i> 小米</a>
              |
              <a target="_blank" href="http://mousebomb-public.qiniudn.com/DinnerFromStarAndroid.apk"><i className="fa fa-download"></i> apk安装包</a>
            </p>*/}

          </ProjectWrapper>

          <ProjectWrapper
            title="Flappy Plane"
            category="手机游戏"
            year="2014"
            imgSrc={require("./img/projects/FlappyPlane.png")}>

            <p>这是一款卡通无厘头风格的休闲游戏，玩家只需要点击屏幕就可以操作游戏，控制卡通飞机通过各种障碍。</p>

            <ul>
              <li>操纵你的飞机上升，躲避障碍物，避免撞上岩石和冰雪、冰山。
              </li><li>自带萌妹子BGM。
            </li><li>跟家人一起乐呵去噢。跟你的朋友比比，谁能开得更远！</li>
            </ul>


            {/*<p>
              <a target="_blank" href="https://itunes.apple.com/cn/app/flappy-plane/id832386945?mt=8"><i className="fa fa-apple"></i> App Store</a>
              |
              <a target="_blank" href="http://app.xiaomi.com/detail/60093"><i className="fa fa-android"></i> 小米</a>
              |
              <a target="_blank" href="http://mousebomb-public.qiniudn.com/FlappyPlaneAndroid.sign.apk"><i className="fa fa-download"></i> apk安装包</a>

            </p>*/}

          </ProjectWrapper>

          <ProjectWrapper
            title="私密笔记"
            category="iOS App"
            year="2014"
            imgSrc={require("./img/projects/SecretNotes.png")}>

            <p>
              私密笔记，是我在平常工作和生活中所需的带有密码保护的备忘录。
            </p>

            <p>
              开发这款软件的初衷是扩充手机上备忘录的功能，它可以用来安全地保存一些系统自带的备忘录里不适合记录的敏感信息，如网络密码、银行卡、私密网址、私密账户等。
              这些数据只会被加密后存放在iPhone上，没有密码无法查看，即使手机丢了也不必担心泄露。
            </p>

            <p>
              特点：
              <ul>
                <li>安全的密码保护，AES加密算法，加密所有文字，没有正确密码，无法解出明文内容。 <br/>
                  别人没有私密笔记的密码，即使我的手机给他，他把我手机破解掉，把数据文件拿出来，也只能得到一堆乱码。自己要记牢私密笔记本身的密码就好了，手机借给别人的时候，只要没解锁私密笔记就没事。随时锁定手机或者返回桌面，私密笔记都会自动上锁。
                </li>
                <li>

                  跨平台的WIFI访问模式，手机连到WIFI时可手动开启此模式，同一个局域网下的电脑或任何能够打开网页的设备（手机、平板等）上都可查看、编辑笔记（也要输入密码）。随时关闭iPhone上的App，资料又安全地锁在手机里。在办公室，随时调出记录；离开办公室，随时带着走。
                </li>
                <li>
                  方便易用，美观大方，简洁易用，操作流畅。
                </li>
                <li>
                  私密笔记2.0 现在iOS7独享，为iOS7风格全新升级，从iOS7风格的图标到扁平化、透明风格的界面，处处贴合新系统的体验！ (喜欢继续使用iOS6的用户不会收到升级提示)
                </li>
              </ul>
            </p><p>建议在使用此App的时候开启手机的iCloud云备份。
          </p>

            {/*<p><a  target="_blank" href="https://itunes.apple.com/cn/app/si-mi-bi-ji/id603879194?mt=8"><img
              src="resources/images/Download_on_the_App_Store_Badge_CN_135x40.png"
              alt=""/></a></p>*/}

          </ProjectWrapper>

          <ProjectWrapper
            title="美食三消"
            category="手机游戏"
            year="2014"
            imgSrc={require("./img/projects/FoodSanXiao.png")}>

            <p>业余时间开发的单机iPad游戏，因为老婆喜欢三消，所以先开发一款三消，又因为美食很可爱，所以选择的主题是美食三消。这个项目开发和拼素材总共历时两周半，上架审核用了额外1周。后续版本持续更新中。</p>
            <p>

              游戏玩法：</p>
            <ul>
              <li>消除三个或以上相同的美食</li>
              <li>4个相同的美食连成一线则获得加辣食物，当它消除时会爆破周围九个。</li>
              <li>横竖两个方向同时有3个美食练成线，会获得十字章食物，当它被消除时横竖两条都会爆掉。</li>
              <li>5个美食连成一线时，获得神奇的棒棒糖。棒棒糖可以跟任意食物交换，场上所有同类食物都会变身成这个食物，一起爆炸。好爽快。</li>
              <li>每50步统计一次成绩。</li>
            </ul>

            <p>三消本来就是令人上瘾的玩法，你能在50步里取得多高的吃货水平呢？快来吃、哦不，是来玩玩看！
            </p>

            {/*<p><a className="fa fa-apple" href="https://itunes.apple.com/cn/app/mei-shi-san-xiao/id670591102?mt=8" target="_blank"> 去App Store下载</a></p>*/}

          </ProjectWrapper>




          <ProjectWrapper
            title="萝莉大冒险"
            category="手机游戏"
            year="2013"
            imgSrc={require("./img/projects/lolitd.png")}>

            <p>《萝莉大冒险》是一款以KUSO童话故事为题材的游戏。其集合英雄养成、城市建设和塔防三大特色为一体。玩家需要一边建设自己的虚拟领地，培养可爱的童话英雄；一边跟随轻松而有趣的故事游历童话世界，最终击败强大的恶魔。各色风格的六大战役，三大难度共四五个关卡精致地图，上百个特色鲜明的怪物，同屏超过四百个单位的超华丽战斗画面，让塔防迷欲罢不能！让萝莉控无法抗拒！
            </p>
            <p>它是我在上海慕和网络任职时负责开发的产品，也是作为主程参加完成的第一个手机游戏项目，这个项目中获得了许多经验，了解了跟页游的区别。项目是PHP (AMFPHP) + AIR
              (用Starling+传统UI)开发，前端Starling+传统UI的性能还是受到许多限制，以后手游我打算全部不采用传统UI了。今年是移动平台产品爆发的一年，我现在兴趣巨大。</p>

          </ProjectWrapper>

          <ProjectWrapper
            title="Byson Electronics"
            category="网站"
            year="2011"
            imgSrc={require("./img/projects/byson.png")}>


            <p>Byson Electronics founded in 2008 manufactures and continues to deliver quality cost-effective photovoltaic
              cables. Byson Electronics aim is to be the global leader in providing interconnect products for photovoltaic
              systems.</p>

            <p>这个网站是与Jianing合作完成的，她负责整体规划与设计，我负责网页与后台功能。</p>

            <p><a className="fa fa-link" href="http://bysonelectronics.com/" target="_blank"> 查看网址</a></p>

          </ProjectWrapper>

          <ProjectWrapper
            title="王国战争"
            category="网页游戏"
            year="2011"
            imgSrc={require("./img/projects/wok.png")}>

            <p>年轻气盛，辞掉工作到上海闭关，3人合作开发一款连我们自己都喜欢玩的网页RTS游戏《王国战争》。</p>

            <p>王国战争最多支持4v4对战，最多400部队壮烈火拼，分塔防和兵海两种战术选择，玩家可以自己决定友军的战术分配、各自的战略职责，共同对抗敌人。每幅游戏地图都有中立建筑物，占领可给一方势力带来优势…</p>

            <p>这个项目因为缺乏美术，失败告终。虽然技术水平不成问题，核心功能也已完成，但由于美术资源不到位，所以建筑兵种和打击动画都很匮乏，地图也是临时画的。透过这个项目，实践了更多WebGame的开发技巧。不断历经磨练，总有成功日。</p>

            <p>这个项目的地图编辑器现在也已经放出源代码下载了。</p>

            <p><a className="fa fa-video-camera" href="javascript:;" onClick={()=>{VideoPlayer.showVideo("wok-defense.mp4",false)}}> 查看视频1</a>
              |
              <a className="fa fa-video-camera" href="javascript:;" onClick={()=>{VideoPlayer.showVideo("wok-attack.mp4")}}> 查看视频2</a>
              |
              <a href="http://www.flashj.cn/wp/wok-td3cmap-editor-download.html" rel="me" target="_blank" className="fa fa-download"> 地编下载</a></p>

          </ProjectWrapper>

          <ProjectWrapper
            title="麻球站长自动采集器"
            category="网站"
            year="2011"
            imgSrc={require("./img/projects/1mochi.png")}>

            <p>当时注册成为了麻球的合作网站主，开了一个Flash游戏网站，做麻球游戏发布。为方便，根据麻球的网站接口，实现Flash小游戏采集发布（PHP、网页等技术）。网站可全自动挂机采集海量Flash游戏内容。</p>

            <p>后来很可惜，因为我不是一个运营的人才，也没有足够精力去运作，网站在1年后就关了。现在如果碰巧有人想做麻球发布商，也许我们可以谈谈我提供技术支持的合作。</p>

          </ProjectWrapper>

          <ProjectWrapper
            title="客栈"
            category="网站"
            year="2011"
            imgSrc={require("./img/projects/inn.png")}>
            <p>客栈，只是一个技术雏形，连名字都没想好，我临时找了一幅图ps成人间客栈。</p>

            <p>
              2010年年初，我很痴迷于饭店经营游戏，碰巧在玩一个很怀旧的游戏——《中华客栈2》，那的确是让我深深难忘怀的一款非常棒的老游戏，小时候玩过一次，之后遗失了那么多年。游戏中的Bug还有一些做得不好的地方我也就忍受了，但长大后的我，很不费力就打通，抵达没追求了的尽头，随之而来的空虚涌上心头。</p>

            <p>
              作为一个遇到问题急于要解决的程序员，不忍心让自己失落。满腔热忱，我立马决定自己开发一款相似的，把它搬到现代的电脑上。还是一样经营客栈，只打尖不开房的客栈。每天挂机看看你的客栈人来人往，生意越做越好，但永远都有各种新挑战，这是人间客栈。</p>

            <p>
              除了核心的厨师小儿掌柜侍者互相配合的职业系统、菜式升级树、采购食材、招聘伙计、员工管理、店内道具、店铺装潢、店铺扩建、店铺升级等功能外，人间客栈还追加规划了道具系统，试想，送给厨师一把“屠龙刀”，不仅可以讨好厨师的向心力，还加厨艺点数多有趣？</p>

            <p>另外还追加了可扩展的剧情脚本系统，完全开放的设计模式，让剧情包mod变得极其强大。</p>

            <p>我还曾经打算加社交元素，再加上内嵌广告，比如买一些游戏内有加成作用的装饰品里放一些真实广告，把广告和游戏性融合在一起。</p>

            <p>想做就做，1个月内完成了核心功能的开发。随后查阅大量资料，寻找美术素材，最后还是放弃自己做美术了，之后因为工作繁忙，一直没找到好的美术协助换皮肤。所以现在这个个人项目一直搁置。想起来略微可惜，全当当年是练手吧。</p>

            <p>
              <a className="btn-show-video fa fa-video-camera" href="javascript:;" onClick={()=>{VideoPlayer.showVideo("inn.mp4")}}> 观看视频</a></p>

          </ProjectWrapper>

          <ProjectWrapper
            title="Mousebomb2009"
            category="网站"
            year="2009"
            roundIco={false}
            imgSrc={require("./img/projects/Mousebomb2009.png")}>

            <p>09年7月27日开始制作的全Flash网站。简洁的外观+主流的技术（中英文无缝切换、深度链接等等）。这个网站的后台管理是独立的AIR+remoting程序，维护的数据会生成为XML文件。</p>

            <p>新纪元已经到来，这个网站的源代码现已免费提供下载。</p>

            <p><a className="fa fa-download" href="/download/" target="_blank"> 下载地址</a> <a href="/2009/" className="fa fa-link" target="_blank"> 查看地址</a></p>

          </ProjectWrapper>

          <ProjectWrapper
            title="Christian BRETON"
            category="网站"
            year="2009"
            imgSrc={require("./img/projects/CB.png")}>
            <p>Christian BRETON 是成立于1990年的法国美容护肤品牌，凭借其卓越的护肤效果，已销往全世界50多个国家。
            </p>

            <p>这个Flash网站是与Jianing合作完成的，她负责整体规划与设计，我负责Flash与后台功能。</p>

            <p><a className="fa fa-link" href="http://www.christian-breton.com" rel="" target="_blank"> 查看网站</a></p>

          </ProjectWrapper>

          <ProjectWrapper
            title="海底世界"
            category="Flash虚拟社区"
            year="2008"
            imgSrc={require("./img/projects/clubfish.png")}>

            <p>2008年，我顶岗实习，机缘巧合，有幸加入了火山和徐灿的创业团队，成为全国首批儿童虚拟社区的开发者。在这里结识了一批有志之士。
            </p>

            <p>
              在这样的创业团队中，不仅是作为ASer参与开发，更多客串了PHP开发和打杂。我具体负责开发游戏内的即时留言墙、支付平台、多种支付接口挂接(全国固话、短信、银行卡、支付宝、财富通等)、日志分析（数据挖掘）系统、游戏道具商城、卡密系统。对整个项目的各方各面都有了了解，大幅提升了实战能力，受益良多。 </p>

          </ProjectWrapper>

          <ProjectWrapper
            title="电子杂志发布平台"
            category="网站/平台"
            year="2008"
            imgSrc={require("./img/projects/ezine.png")}>
            <p>Flash的电子杂志发布、查看平台。

            </p>

            <p>查看功能有： 返回封面、向前、向后、返回封底、放大、缩小、原始尺寸、加入书签(下次浏览从保存位置开始浏览)、登陆、帮助，可以使用鼠标滚轴浏览较长的页面(向上滚动,向下滚动)、滚动页面导航、回到目录、参与评论(评论系统
              )。 使用鼠标滚轴浏览较长的页面(向上滚动,向下滚动)。</p>

            <p>后台管理，可方便添加自己的期刊、页面，查看登陆用户数据统计，查看专题评论、用户数据等。</p>

            <p>客户端提供API，基于此，让杂志制作团队使用简单的API即可实现目录、跳转、音乐播放等功能。</p>


          </ProjectWrapper>

          <ProjectWrapper
            title="饭否AIR客户端"
            category="桌面App"
            year="2007"
            imgSrc={require("./img/projects/fanfou.png")}>

            <p>FLEX/Flash开发的饭否客户端。

            </p>

            <p>风靡一时的饭否是国内第一款微博服务，07年正值Adobe发布AIR，我也在这个时期透过开发饭否客户端来实践AIR的客户端开发。当时AIR版本似乎还没到1.0。</p>

            <p>一个典型的微博客户端，登陆，收发微博，最小化到系统托盘，根据用户反馈迭代了少许时间。后来因为众所周知的原因，某些不可抗力，饭否关闭了，之后我也就不再更新了。</p>

          </ProjectWrapper>

          <ProjectWrapper
            title="Mousebomb2007"
            category="网站"
            year="2007"
            imgSrc={require("./img/projects/Mousebomb2007.png")}>
            <p>06年结束了，网站也该在一周年生日时升级咯，
              07版本是在07年1月7日开始制作，到4月15日最终完成。
              朋友们给我提出了很多开发上的意见，我总结了败笔，吸取了教训，对Flash网站整体有了新的认识。

              当时IE6还是主流，所以制作的时候只考虑的IE6浏览器。也因为想要滚动条颜色而没有采用web标准。
            </p>

            <p>新纪元已经到来，这个网站的源代码现已免费提供下载。</p>

            <p><a className="fa fa-download" href="/download/" rel="me" target="_blank"> 下载地址</a></p>

          </ProjectWrapper>

          <ProjectWrapper
            title="Mousebomb2006"
            category="网站"
            year="2006"
            imgSrc={require("./img/projects/Mousebomb2006.png")}>
            <p>鼠标炸弹个人网站2006， 还有个名字叫做“蓝色联想”。

              在2006年5月13日开始制作的，大概是到8月3日才最终完成。当时正是学习Flash网站最来劲的一段时间，嘿嘿。

              由于当时确实非常才疏学浅，又什么都想尝试尝试，设计中使用了不少国外插画素材。同时对细节的把握也不够，画面比较粗糙。整个Flash用了N多不同风格的字体，导致风格不统一……</p>

            <p>新纪元已经到来，这个网站的源代码现已免费提供下载。</p>

            <p><a className="fa fa-download" href="/download/" target="_blank"> 下载地址</a></p>
          </ProjectWrapper>

          <ProjectWrapper
            title="森茂空间艺术设计"
            category="网站"
            year="2006"
            imgSrc={require("./img/projects/sunmoon.png")}>
            <p>2006年夏天，我在深圳参与社会实践，为两家公司做Flash网站，这是其中一家。</p>

            <p>
              深圳市森茂家俱艺术设计有限公司是一家专业从事写字楼、室验室、酒店、样板房及家居的室内设计及施工的专业设计公司。公司成立至今，以准确的设计定位及敬业的设计服务精神，成功的为开发商及投资商提供了高标准，高品味的样板房、酒店式公寓、写字楼、实验室、家居等专案的室内设计及施工服务。</p>

          </ProjectWrapper>

          <ProjectWrapper
            title="方得环艺设计"
            category="网站"
            year="2006"
            imgSrc={require("./img/projects/finder.png")}>

            <p>2006年夏天，我在深圳参与社会实践，为两家公司做Flash网站，这是其中一家。</p>

            <p>公司崇尚设计，有着一群充满活力、创造力的优秀设计师。 公司始终坚持为客户提供高品质的生活环境，为生活空间战线最舒适、完美的方方面面。
              发现美好生活，一切源于设计！
            </p>

          </ProjectWrapper>



        </div>

        <VideoPlayer/>


      </div>);
  }

}
class ProjectWrapper extends Component
{

  static propTypes = {
    imgSrc: PropTypes.any,
    title: PropTypes.string ,
    category: PropTypes.string ,
    year: PropTypes.string ,
    roundIco: PropTypes.bool,
  };
  static defaultProps={
    roundIco:true,
  };

  constructor(props)
  {
    super(props);
    this.state = {expanded: false};
  }

  render()
  {
    let {imgSrc} = this.props;
    let {expanded} = this.state;
    if ( expanded ){
      return (
        <ProjectDetail {...this.props} onClick={this.onClickLi.bind(this)}/>
      );
    }else
    {
      return (
      <ProjectLi imgSrc={imgSrc} roundIco={this.props.roundIco} onClick={this.onClickLi.bind(this)}/>
      );
    }
  }

  onClickLi()
  {
    this.setState({expanded: !this.state.expanded});
  }
}

class ProjectLi extends Component
{
  static propTypes = {
    imgSrc: PropTypes.any,
    onClick: PropTypes.func,
    roundIco : PropTypes.bool
  };

  constructor(props)
  {
    super(props);
    this.state = {hide: true};
  }
  componentWillUnmount()
  {
    clearTimeout(this.itv);
  }

  componentDidMount()
  {
    this.itv = setTimeout(()=>{
      this.setState({hide:false});
    },10);
  }

  render()
  {
    let {hide} = this.state;
    let {onClick , imgSrc ,roundIco} = this.props;
    return (
      <div className={"ProjectLi"+(hide?" hide":"") +(roundIco?" r":"")}>
        <a href="javascript:;" className="ProjectLiA" onClick={onClick}>
          <img src={imgSrc} alt=""/>
        </a>
      </div>
    );
  }

}

class ProjectDetail extends Component{

  static propTypes = {
    imgSrc: PropTypes.any,
    title: PropTypes.string ,
    category: PropTypes.string ,
    year: PropTypes.string ,
    onClick: PropTypes.func,
    roundIco: PropTypes.bool,
  };


  constructor(props)
  {
    super(props);
    this.state = {spawn: false};
  }

  componentWillUnmount()
  {
    clearTimeout(this.itv);
  }

  componentWillMount()
  {
  }

  componentDidMount()
  {
    this.itv = setTimeout(()=>{
      this.setState({spawn:true});
    },10);
  }

  render()
  {
    let {spawn} = this.state;
    let {onClick , imgSrc ,title,category,year,roundIco} = this.props;
    return (
      <div className={"ProjectDetail" + (spawn?" spawn":"")}>
        <div className="hr"></div>


        <div className="titleRow">
          <div className={"iconImg"+(roundIco?" r":"")} onClick={onClick}><img src={imgSrc} alt=""/>
          </div>
          <div className="titles">
            <h2>{title}</h2>
            <h3>{category}</h3>
            <h3>{year}</h3>
          </div>
        </div>
        <div className="intro">
          {this.props.children}
        </div>


        <div className="hr"></div>
      </div>

    );
  }
}