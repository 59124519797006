
import { Pass } from "three/examples/jsm/postprocessing/Pass.js";
import * as THREE from 'three';

var TransitionPass = function( transition ,clock, overrideMaterial, clearColor, clearAlpha  ) {

  Pass.call( this );

  this.transition = transition;
  this.clock = clock;

  // this.overrideMaterial = overrideMaterial;

  this.clearColor = clearColor;
  this.clearAlpha = ( clearAlpha !== undefined ) ? clearAlpha : 0;

  this.clear = true;
  this.clearDepth = false;
  this.needsSwap = false;

};

TransitionPass.prototype = Object.assign( Object.create(Pass.prototype), {

  constructor : TransitionPass,

  render: function ( renderer, writeBuffer, readBuffer /*, deltaTime, maskActive */ ) {

    var oldAutoClear = renderer.autoClear;
    renderer.autoClear = false;
    var oldClearColor, oldClearAlpha, oldOverrideMaterial;

    if ( this.overrideMaterial !== undefined ) {

      oldOverrideMaterial = this.scene.overrideMaterial;

      this.scene.overrideMaterial = this.overrideMaterial;

    }

    if ( this.clearColor ) {

      oldClearColor = renderer.getClearColor().getHex();
      oldClearAlpha = renderer.getClearAlpha();

      renderer.setClearColor( this.clearColor, this.clearAlpha );

    }

    if ( this.clearDepth ) {

      renderer.clearDepth();

    }

    renderer.setRenderTarget( this.renderToScreen ? null : readBuffer );

    // TODO: Avoid using autoClear properties, see https://github.com/mrdoob/three.js/pull/15571#issuecomment-465669600
    if ( this.clear ) renderer.clear( renderer.autoClearColor, renderer.autoClearDepth, renderer.autoClearStencil );

    this.transition.render(this.clock.getDelta() , this.renderToScreen ? null : readBuffer );

    if ( this.clearColor ) {

      renderer.setClearColor( oldClearColor, oldClearAlpha );

    }

    if ( this.overrideMaterial !== undefined ) {

      this.scene.overrideMaterial = oldOverrideMaterial;

    }

    renderer.autoClear = oldAutoClear;

  }

});

export {TransitionPass};
