/**
 * Created by rhett on 2018/6/15.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import App from "./App";
import "./MainPage.css";
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import Facade from "./Facade";
import Notify from "./Notify";
import MBHeader from "./MBHeader";
import "./ResumePage.css";


export default class ResumePage extends Component
{
  static propTypes = {
    //height :PropTypes.number,//高度
  };

  constructor(props)
  {
    super(props);
    this.state = {};
  }


  componentWillUnmount()
  {

  }

  componentWillMount()
  {
  }

  componentDidMount()
  {
  }


  render()
  {
    return (
      <div className="ResumePage  ">

        <div className="hr"></div>


        <div className="career-item">
          <div className="titleRow"><h3>独立开发者<span>全栈</span></h3>
            <h4>2014.03 ~至今</h4></div>
          <div className="p">

            <p>早年因为对Flash的热爱，做过很多Flash网站，在网上大量被传阅和转载。</p>
            <p>手游红利时代，做手机单机小游戏赚广告费，开发过一套自动生成游戏的引擎，赋能整个团队，做到了1个亿左右的下载，帮团队好几个成员实现了上百万的收益。</p>
            <p>经历了几次创业和团队作战之后，磨炼了一身技能的我，业余时间还坚持在做一些独立项目。</p>

            <p className="tag"><i className="fa fa-code"></i> 语言:
              <span>OC</span>, 
              <span>C++</span>, 
              <span>AS3</span>, 
              <span>PHP</span>, 
              <span>MySQL</span>, 
              <span>JS</span>, 
              <span>HTML5</span>, 
              <span>C#</span>
            </p>
            <p className="tag"><i className="fa fa-wrench"></i> 框架:
              <span>React</span>, 
              <span>React Native</span>, 
              <span>Unity</span>, 
              <span>Cocos2d</span>, 
              <span>RobotLegs</span>, 
              <span>Mousebomb Framework</span>, 
              <span>AMFPHP</span>, 
              <span>jQuery</span>, 
              <span>Bootstrap</span>, 
              <span>Cordova</span>
            </p>

          </div>
          <div className="hr"></div>
        </div>

        <div className="career-item">
          <div className="titleRow"><h3>连悦网络<span>主程</span></h3>
            <h4>2017.07 ~至今</h4></div>
          <div className="p">

            <p> 公司提供数字化营销的解决方案，也包括软硬件结合的O2O场景综合解决方案。因此，工作中负责跨平台的App开发、公司的各项活动和展示的H5交互页面开发、微信小程序开发等。同时为工作流进行工具链的研发和优化。</p>

            <p className="tag"><i className="fa fa-code"></i> 语言:
              <span>JS</span>, 
              <span>HTML5</span>, 
              <span>OC</span>, 
              <span>Java</span>
              <span>Kotlin</span>
            </p>
            <p className="tag"><i className="fa fa-wrench"></i> 框架:
              <span>React</span>, 
              <span>React Native</span>, 
              <span>QCloud</span>, 
              <span>微信</span>
            </p>
          </div>
          <div className="hr"></div>
        </div>


        <div className="career-item">
          <div className="titleRow"><h3>深圳冰川网络<span>主程</span></h3>
            <h4>2016.10 ~2017.05</h4></div>
          <div className="p">

            <p>对公司之前已上线的页游产品进行性能分析和优化。</p>
            <p>带头研发基于Flash的网页3D MMOARPG，基于Away3D (AGAL、Stage3D)做底层修改，定制整合。</p>
            <p>从无到有开发了专用的3D地图编辑器，地形可视化涂抹，自动生成/导入高度图，地皮纹理融合，模型放置，刚体碰撞，斜坡，路点摆放等功能。尚未完成光影烘培。</p>
            <p>设计和开发了3D游戏客户端场景渲染、角色渲染、MVC框架等核心功能。</p>
            <p>研发了手机房卡麻将。</p>

            <p className="tag"><i className="fa fa-code"></i> 语言:
              <span>AS3</span>, 
              <span>C#</span>
            </p>
            <p className="tag"><i className="fa fa-wrench"></i> 框架:
              <span>Away3D</span>, 
              <span>Starling</span>,
              <span>RobotLegs</span>,
              <span>Unity</span>
            </p>

          </div>
          <div className="hr"></div>
        </div>

        <div className="career-item">
          <div className="titleRow"><h3>江游网络<span>主程</span></h3>
            <h4>2014.05 ~2015.09</h4></div>
          <div className="p">

            <p> 内部培训和技术支持；</p>
            <p> 后从事网页游戏项目《血战苍穹》开发。采用Starling框架，开发的大型MMOARPG网页游戏。</p>

            <p className="tag"><i className="fa fa-code"></i> 语言: <span>AS3</span></p>
            <p className="tag"><i className="fa fa-wrench"></i> 框架: <span>RobotLegs</span>, <span>Starling</span>
            </p>
          </div>
          <div className="hr"></div>
        </div>

        <div className="career-item">
          <div className="titleRow"><h3>iUX Labs<span>主程</span></h3>
            <h4>2013.03 ~2014.04</h4></div>
          <div className="p">

            <p> 提供基于3D互联网和移动互联网，在商业、文化（教育）、旅游、健康领域的互联网用户体验咨询、设计、开发，以及互联网及移动App项目定制开发、运营维护及可运营平台搭建。</p>

            <p> 创新公司，对产品体验尤其关注。为顾客传递价值。 </p>

            <p className="tag"><i className="fa fa-code"></i> 语言: <span>OC</span>, <span>PHP</span>, 
              <span>AS3</span>, <span>HTML5</span>, <span>C++</span></p>
            <p className="tag"><i className="fa fa-wrench"></i> 框架: <span>Mousebomb Framework</span>, <span>RobotLegs</span>, 
              <span>Bootstrap</span>, <span>Codeigniter</span></p>
          </div>
          <div className="hr"></div>
        </div>

        <div className="career-item">
          <div className="titleRow"><h3>上海慕和网络<span>主程</span></h3>
            <h4>2012.06 ~2013.02</h4></div>
          <div className="p">

            <p>
              开发移动设备版游戏《萝莉大冒险》。
            </p>

            <p>
              负责客户端通信层、数据层；服务器端程序。
            </p>
            <p className="tag"><i className="fa fa-code"></i> 语言: <span>AS3</span>, <span>PHP</span>, 
              <span>OC</span></p>
            <p className="tag"><i className="fa fa-wrench"></i> 框架: <span>RobotLegs</span>, <span>Starling</span>, 
              <span>Mousebomb Framework</span>, <span>AMFPHP</span></p>

          </div>
          <div className="hr"></div>
        </div>

        <div className="career-item">
          <div className="titleRow"><h3>深圳批客网络<span>主程</span></h3>
            <h4>2011.10 ~2012.06</h4></div>
          <div className="p">

            <p>创业团队。</p>

            <p>开发在线有奖竞技娱乐平台: PK有礼。负责Flash客户端开发。</p>
            <p className="tag"><i className="fa fa-code"></i> 语言: <span>AS3</span></p>
            <p className="tag"><i className="fa fa-wrench"></i> 框架: <span>RobotLegs</span>, <span>Mousebomb Framework</span>
            </p>

          </div>
          <div className="hr"></div>
        </div>

        <div className="career-item">
          <div className="titleRow"><h3>上海奥盛网络<span>主程</span></h3>
            <h4>2011.02 ~2011.05</h4></div>
          <div className="p">

            <p>SLG网页游戏，全职外包，客户端开发。
            </p>

            <p className="tag"><i className="fa fa-code"></i> 语言: <span>AS3</span></p>
            <p className="tag"><i className="fa fa-wrench"></i> 框架: <span>Mousebomb Framework</span></p>
          </div>
          <div className="hr"></div>
        </div>

        <div className="career-item">
          <div className="titleRow"><h3>3Z5D<span>主程</span></h3>
            <h4>2010.08 ~2011.02</h4></div>
          <div className="p">

            <p>创业团队。</p>

            <p>开发独立网页即时战略游戏项目《王国战争》，负责整个客户端开发，在stage3D之前，自己写了一套高性能面向显示节点的渲染引擎，为此游戏对兵海同屏作战的画面提供强力支撑。并将此项目的地图编辑器开源。</p>
            <p className="tag"><i className="fa fa-code"></i> 语言: <span>AS3</span></p>
            <p className="tag"><i className="fa fa-wrench"></i> 框架: <span>Mousebomb Framework</span></p>

          </div>
          <div className="hr"></div>
        </div>

        <div className="career-item">
          <div className="titleRow"><h3>上海酷噜网络<span>程序</span></h3>
            <h4>2008.10 ~2009.05</h4></div>
          <div className="p">

            <p> 开发儿童社区《海底世界》。</p>

            <p> 具体负责开发游戏内的即时留言墙、支付平台、多种支付接口挂接(全国固话、短信、银行卡、支付宝、财富通等)、日志分析（数据挖掘）系统、游戏道具商城、卡密系统。 </p>

            <p className="tag"><i className="fa fa-code"></i> 语言: <span>AS3</span>, <span>PHP</span>, 
              <span>MySQL</span>, <span>JS</span></p>
            <p className="tag"><i className="fa fa-wrench"></i> 框架: <span>PureMVC</span>, <span>AMFPHP</span>, 
              <span>Smarty</span></p>

          </div>
          <div className="hr"></div>
        </div>


      </div>);
  }

}