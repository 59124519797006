import React, { Component } from 'react';
import * as THREE from 'three';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';
import Transition from "./webgl/Transition";
import {TransitionPass} from "./webgl/passes/TransitionPass";
import {MBGlitchPass} from "./webgl/passes/MBGlitchPass";
import Scene1 from "./webgl/scenes";

class ThreeBim extends Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }

  initThree(){


    var container, stats;
    var renderer;
    var transition;

    var clock = new THREE.Clock();

    init();
    animate();

    var sceneA, sceneB;
    function init() {


      container = document.getElementById( "canvas-frame" );

      renderer = new THREE.WebGLRenderer( { antialias: true } );
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize( window.innerWidth, window.innerHeight );
      container.appendChild( renderer.domElement );

      speedA = new THREE.Vector3( 0, - 0.4, 0 );
      speedB = new THREE.Vector3( 0, 0.2, 0.1 );
      sceneA = new Scene1( renderer,"cube", 500, 1200, 120, speedA, 0xffffff );
      sceneB = new Scene1( renderer,"sphere", 500, 2000, 50, speedB, 0xfafafa );

      transition = new Transition( sceneA, sceneB ,renderer,clock);




      initComposer();
      initTouchHandler();

    }

    function animate() {

      requestAnimationFrame( animate );

      render();

    }

    function render() {

      // renderer.setRenderTarget(  renderTarget );
      //
      // transition.render( clock.getDelta() );

      lerpSpeed();
      composer.render();

    }


    var composer;
    function initComposer()
    {
      composer = new EffectComposer( renderer );

      var renderPass = new TransitionPass( transition,clock );
      composer.addPass( renderPass );

      var glitchPass = new MBGlitchPass();
      composer.addPass( glitchPass );
    }

    var lastClientX =0;
    var lastClientY =0;
    function initTouchHandler( )
    {
      let handler = (e)=>{
        //new THREE.Vector3( 0, - 0.4, 0 ) // 0.2
        if ( Math.abs(e.clientX -lastClientX) >5 )
        {
          let speed = (e.clientX-lastClientX)/50;
          if(speed>0.4) speed = 0.4;
          if(speed<-0.4) speed = -0.4;
          speedA.y = speed;
          speedB.y = -speed/2;
          lastClientX = e.clientX;
        }
        if ( Math.abs(e.clientY -lastClientY) >5 )
        {
          let speed = (e.clientY-lastClientY)/80;
          if(speed>0.4) speed = 0.4;
          if(speed<-0.4) speed = -0.4;
          speedA.z = speed;
          speedB.z = -speed/2;
          lastClientY = e.clientY;
        }
      };
      document.addEventListener("mousemove",handler);
      document.addEventListener("touchmove",handler);
    }

    var speedA,speedB;
    function lerpSpeed()
    {
      sceneA.rotationSpeed.y += (speedA.y-sceneA.rotationSpeed.y)/5;
      sceneA.rotationSpeed.z += (speedA.z-sceneA.rotationSpeed.z)/5;
      sceneB.rotationSpeed.y += (speedB.y-sceneB.rotationSpeed.y)/5;
      sceneB.rotationSpeed.z += (speedB.z-sceneB.rotationSpeed.z)/5;
    }



  }

  /**
   * 开始Three
   *
   * @memberof ThreeBim
   */
  componentDidMount(){
    this.initThree();
    //
  }
  render() {
    return (
      <div id='canvas-frame'>
      </div>
  );
  }
}

export default ThreeBim;

