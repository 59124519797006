/**
 * Created by rhett on 2018/6/16.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./VideoPlayer.css";

export default class VideoPlayer extends Component
{
  static propTypes = {
    //height :PropTypes.number,//高度
  };

  static _instance = null;

  constructor(props)
  {
    super(props);
    this.state = {show:false,vURL:""};
    VideoPlayer._instance = this;
  }

  static showVideo( vname )
  {
    if (VideoPlayer._instance)
    {
      let vURL = require('./videos/' + vname);
      VideoPlayer._instance.setState({vURL,show:true});
    }
  }

  componentWillUnmount()
  {
  }

  componentWillMount()
  {
  }

  componentDidMount()
  {
  }

  render()
  {
    let {show ,vURL} = this.state;
    return (
      <div id="rsVideoPlayer" className={show?" show":""}>
        <div className="rsVideoClose">
          <a href="javascript:;" onClick={this.onCloseClick.bind(this)}>
            <img src={require("./img/closevideo.png")} alt=""/>
          </a>
        </div>
        <div id="rsVideo">
          {show&&vURL && <video autoplay controls src={vURL}></video>}
        </div>
      </div>);
  }

  onCloseClick()
  {
    this.setState({show:false});
  }

}