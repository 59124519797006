/**
 * Created by rhett on 2017/7/18.
 */


import Notify from "./Notify";
export default class Facade
{

    static listenersMap = new Map();

    /**
     * 发送通告
     * @param data 数据
     * @param name 通告类型名
     */
    static sendNotify(name, sender, data)
    {
        var notify = new Notify(name, sender, data);

        if (Facade.listenersMap.has(name))
        {
            let arr = Facade.listenersMap.get(name);
            arr.forEach((handler) =>
            {
                handler(notify);
            });
        }
    }

    /**
     * 注册对通告的监听.
     * 当触发时将回调handler(Notify)
     */
    static regListener(notifyName, handler)
    {
        if (!Facade.listenersMap.has(notifyName))
        {
            Facade.listenersMap.set(notifyName, []);
        }
        Facade.listenersMap.get(notifyName).push(handler);
    }

    /**
     * 移除监听.
     * @param notifyName
     * @param handler
     */
    static removeListener(notifyName, handler)
    {
        if (Facade.listenersMap.has(notifyName))
        {
            let arr = Facade.listenersMap.get(notifyName);
            let index = arr.indexOf(handler);
            if (index != -1)
                arr.splice(index, 1);
        }
    }

}