/**
 * Created by rhett on 2018/6/15.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import App from "./App";
import "./MainPage.css";
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import Facade from "./Facade";
import Notify from "./Notify";
import MBHeader from "./MBHeader";


export default class MainPage extends Component
{
  static propTypes = {
    //height :PropTypes.number,//高度
  };

  constructor(props)
  {
    super(props);
    this.state = {};
  }


  componentWillUnmount()
  {
    // Facade.removeListener(Notify.MBHEADER_CONTAIN_H_CHANGED, this._onResize);

  }

  componentWillMount()
  {
    // this._onResize = this.onResize.bind(this);
    // Facade.regListener(Notify.MBHEADER_CONTAIN_H_CHANGED, this._onResize);
  }

  componentDidMount(){
  }
  // onResize( n )
  // {
  //   this.setState({h : n.data});
  // }


  render()
  {
    // let syncWinH = App.screenHelper.getWinH();
    // let h  = syncWinH - MBHeader.spacerH;
    //
    // if ( h < 240 ) h = 240;
    // let mainPageStl = {height: h +"px"};
    return (
      <div className="MainPage  "   >
        <div className="hr"></div>
        <h1 className="name">高瑞德</h1>
        <div className="hr"></div>
        <div className="p">
          <p>18665899347</p>
          <p>QQ: 405572555</p>
          <p>MOUSEBOMB@GMAIL.COM</p>
        </div>
        <div className="hr"></div>

        <div className="link">
          {/*<a href=""><i className="fa fa-weixin"></i></a>*/}
          <a href="https://github.com/mousebomb/" target="_blank"><i className="fa fa-github"></i></a>
          {/*<a href="http://t.sina.com.cn/mousebomb" target="_blank"><i className="fa fa-weibo"></i></a>*/}
        </div>
          <div className="link">
            <a href="https://beian.miit.gov.cn/" target="_blank"><small>粤ICP备16007529号-1</small></a>
          </div>
      </div>);
  }

}