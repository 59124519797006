import React, { Component } from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import ScreenHelper from "./ScreenHelper";
import MainPage from "./MainPage";
import 'react-id-swiper/src/styles/css/swiper.css'
import MBHeader from "./MBHeader";
import "font-awesome/css/font-awesome.css";
import ResumePage from "./ResumePage";
import ProjectsPage from "./ProjectsPage";
import MomentsPage from "./MomentsPage";
import ThreeBim from "./ThreeBim";
import VideoBg from "./VideoBg";



class App extends Component {

  static screenHelper = new ScreenHelper();

  static ROUTE_ROOTPATH ="(/2018)?/";  // 本地是根目录，兼容服务器上带前缀目录

  componentWillUnmount()
  {
    App.screenHelper.dispose();
  }
  componentWillMount()
  {
    App.screenHelper.init(this);
  }


  render() {

    return (
      <div className="App">
        <VideoBg/>

        <MBHeader/>

        <Switch>
          <Route path={App.ROUTE_ROOTPATH}  exact={true} component={MainPage} />
          <Route path={App.ROUTE_ROOTPATH+"resume"}  exact={true} component={ResumePage} />
          <Route path={App.ROUTE_ROOTPATH+"projects"}  exact={true} component={ProjectsPage} />
          <Route path={App.ROUTE_ROOTPATH+"moments"}  exact={true} component={MomentsPage} />
          <Route path={App.ROUTE_ROOTPATH}  component={MainPage} />
        </Switch>

      </div>
    );
  }
}

export default App;
