/**
 * Created by rhett on 2018/6/11.
 */
import Facade from "./Facade";
import Notify from "./Notify";
export default class ScreenHelper
{


  _appInstance = null;


  lastWinW = 0;
  lastWinH = 0;
  /** 获得窗口高度 */
  getWinH()
  {
    return window.innerHeight;
  }
  getWinW()
  {
    return window.innerWidth;
  }

  init(appInstance)
  {
    this._appInstance = appInstance;

    this._onResizeHandle = this.onResizeHandle.bind(this);
    window.addEventListener("resize", this._onResizeHandle);
    this.onResizeHandle();

  }

  onResizeHandle()
  {
    console.log("App/onResizeHandle", this.getWinH());
    let winH = this.getWinH();
    let winW = this.getWinW();
    let eles = document.getElementsByClassName("hSync");
    for(let i = 0;i<eles.length;i++)
    {
      eles[i].style.height = winH+"px";
    }
    eles = document.getElementsByClassName("minHSync");
    for(let i = 0;i<eles.length;i++)
    {
      eles[i].style.minHeight = winH+"px";
    }


    if ( this.lastWinW != winW )
      Facade.sendNotify(Notify.WIN_W_CHANGED,this,winW);
    if ( this.lastWinH != winH )
      Facade.sendNotify(Notify.WIN_H_CHANGED,this,winH);
    this.lastWinH = winH;
    this.lastWinW = winW;
  }


  dispose()
  {
    window.removeEventListener("resize", this._onResizeHandle);
  }
}