/**
 * Created by rhett on 2017/8/17.
 */

export default class Notify
{


    static WIN_W_CHANGED = "WIN_W_CHANGED";
    static WIN_H_CHANGED = "WIN_H_CHANGED";

    static MBHEADER_CONTAIN_H_CHANGED = "MBHEADER_CONTAIN_H_CHANGED";

    static MOMENTS_LIST_CHANGED = "MOMENTS_LIST_CHANGED";


  constructor(name, target, data)
    {
        this.name = name;
        this.target = target;
        this.data = data;
    }

    //通知名
    name = "";
    //发送者
    target = null;
    //携带数据
    data = null;







}